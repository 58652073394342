@import '../../../styles/_colors';
@import '../../../styles/_breakpoints';

.wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 360px;
    width: 100%;
    max-width: 760px;
    height: 42px;
    border: 1px solid $main-border-color;
    border-radius: 10px;

    .searchInput {
        width: 100%;
        font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        letter-spacing: 0.1px;
        background: $background-lite;
        border: none;
        border-radius: 10px 0 0 10px;
        border-right: 1px solid $brand-white;
        height: 100%;
        padding: 0 40px 0 50px;
        color: $text-gray;

        &::placeholder {
            color: $icons-gray;
            font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.1px;
        }

        &:focus-visible {
            outline: none;
        }

        &:disabled {
            opacity: 0.6;
        }
    }

    .closeIcon {
        cursor: pointer;
        position: absolute;
        right: 165px;
        padding: 4px;
        width: 22px;
        height: 22px;
        z-index: 3;
    }

    .searchInputIcon {
        cursor: pointer;
        position: absolute;
        left: 12px;
        min-width: 20px;
        width: 20px;
        min-height: 20px;
        height: 20px;
        z-index: 3;

        & path {
            fill: $text-gray-secondary;
        }
    }

    .goSearchButton {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        padding-left: 17px;
        cursor: pointer;
        width: 155px;
        min-width: 155px;
        max-width: 155px;
        border-radius: 0 10px 10px 0;
        border: none;
        background: $background-lite;
        border-left: 1px solid $brand-white;
        height: 100%;
        font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
        font-size: 10px;
        line-height: 12px;
        font-weight: 400;
        letter-spacing: 0.1px;
        color: $icons-gray;

        .buttonText {
            display: inline;
        }
    }

    .searchErrorHelper {
        position: absolute;
        left: 40px;
        bottom: -12px;
        color: $brand-red;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0.1px;
    }
}

@media screen and (max-width: $breakpoint-landscape-tablet) {
    .wrapper {
        .goSearchButton {
            width: 47px;
            min-width: 47px;

            .buttonText {
                display: none;
            }
        }

        .closeIcon {
            right: 55px;
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .wrapper {
        min-width: 290px;
        margin: 0 15px;

        .searchInput {
            padding: 0 80px 0 70px;
            border-right: 0;
            border-radius: 10px;
        }

        .searchInputIcon {
            left: 45px;
            width: 16px;
            min-width: 16px;
            height: 16px;
            min-height: 16px;
        }

        .goSearchButton {
            display: none;
            width: 0;
            min-width: 0;
            margin: 0;

            .buttonText {
                display: none;
            }
        }
    }
}
