@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    src: url(./fonts/Roboto-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: url(./fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: url(./fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: url(./fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: '5ka Sans Design';
    src: url(./fonts/5kaSansDesign-Regular.otf) format('opentype');
}

@font-face {
    font-family: '5ka Sans Design';
    font-weight: 100;
    src: url(./fonts/5kaSansDesign-Thin.otf) format('opentype');
}

@font-face {
    font-family: '5ka Sans Design';
    font-weight: 300;
    src: url(./fonts/5kaSansDesign-Light.otf) format('opentype');
}

@font-face {
    font-family: '5ka Sans Design';
    font-weight: 400;
    src: url(./fonts/5kaSansDesign-Regular.otf) format('opentype');
}

@font-face {
    font-family: '5ka Sans Design';
    font-weight: 600;
    src: url(./fonts/5kaSansDesign-SemiBold.otf) format('opentype');
}

@font-face {
    font-family: '5ka Sans Design';
    font-weight: 900;
    src: url(./fonts/5kaSansDesign-Black.otf) format('opentype');
}

body {
    font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif !important;
    margin: 0;
    padding: 0;
}

/* start of hiding scrollbar */
html * {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

html::-webkit-scrollbar-thumb {
    background-color: transparent;
}

html::-webkit-scrollbar {
    width: 0 !important;
    background-color: transparent;
}
html::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
}

/* end of hiding scrollbar */

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
span {
    margin: 0;
}

table video {
    max-width: 100% !important;
    max-height: 100% !important;
}

#root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    flex: auto;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.pb-5 {
    padding-bottom: 5px;
}

.pt-0 {
    padding-top: 0;
}

.mr-10 {
    margin-right: 10px;
}

.ml-10 {
    margin-left: 10px;
}

input::placeholder {
    color: #878786 !important;
}

input:focus,
button:focus {
    outline: none !important;
}

.rotate-180 {
    transform: rotate(180deg);
}

/*  Table pagination styles */
.table-pagination {
    display: flex;
    min-width: 30%;
    width: 50%;
    max-width: 60%;
    padding: 0;
    margin: 0;
    flex-direction: row;
    text-decoration: none;
    list-style-type: none;
}

.table-page-link {
    cursor: pointer;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    background: transparent;
    padding: 10px 5px;
    margin: 0;
    font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
    color: #878786;
    text-decoration: none;
}

.table-page-link:active,
.table-page-link:focus {
    outline: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.table-page-link:hover {
    color: #00923a;
    background-color: transparent;
}

.table-prev-link,
.table-next-link {
    display: flex;
    align-items: center;
    padding: 10px 5px;
    margin: 0;
    color: #00923a;
    font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.display-none {
    display: none;
}

.table-active-page {
    color: #00923a;
}

.article-error-editor .tox-tinymce {
    border: 1px solid #e52322;
}

.MuiSnackbar-root {
    top: 55px;
}
.MuiSnackbar-root .MuiPaper-root {
    border-radius: 5px;
    background: #fa9c96;
    box-shadow: none;
    line-height: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.MuiSnackbar-root .MuiPaper-root .MuiAlert-message {
    line-height: 16px;
}
.MuiSnackbar-root .MuiPaper-root .MuiAlert-icon,
.MuiSnackbar-root .MuiPaper-root .MuiAlert-action {
    padding: 0;
    display: flex;
    align-items: center;
}
.MuiSnackbar-root .MuiPaper-root .MuiAlert-action {
    padding-left: 16px;
}

.MuiClock-pin,
.MuiClockPointer-noPoint,
.MuiClockPointer-root,
.MuiPickersCalendar-root .MuiPickersDay-root.Mui-selected,
.MuiPickersYear-yearButton.Mui-selected {
    background-color: #00923a !important;
}

.css-d0ukip-MuiButtonBase-root-MuiButton-root {
    color: #00923a !important;
}

.MuiClockPointer-thumb {
    border-color: #00923a !important;
}

.schedule-cron-select .ant-select-selector {
    border-radius: 4px !important;
}

.schedule-cron-select .ant-select-selector:hover,
.schedule-cron-select .ant-select-selector:active,
.schedule-cron-select .ant-select-selector:focus-within,
.schedule-cron-select .ant-select-selector:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: #00923a !important;
}

.ant-select-item-option-selected {
    background-color: rgba(0, 146, 58, 0.5) !important;
}

.btn {
    cursor: pointer;
    color: #00923a;
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1px;
}

.btn:hover,
.btn:active {
    text-decoration: underline;
    color: #00742e;
}
