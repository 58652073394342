@import '_colors';
@import '_breakpoints';

@mixin actionBtn {
    font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
    cursor: pointer;
    height: 32px;
    background-color: $brand-white;
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0;
    color: $text-light-gray;
    padding: 7px 12px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin-left: 10px;

    .actionBtnIconSave {
        width: 15px;
        height: 16px;
        margin-right: 7px;
    }

    .actionBtnIconView {
        width: 17px;
        height: 13px;
        margin-right: 7px;
    }

    .actionBtnIconCancel {
        width: 12px;
        height: 12px;
        margin-right: 9px;
    }
}

@mixin pageContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    background-color: $brand-white;
    font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
    font-weight: 400;
    letter-spacing: 0;
}

@mixin hrStyle {
    width: 100%;
    border: none;
    color: $main-border-color;
    background-color: $main-border-color;
    height: 1px;
}

@mixin inputBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

@mixin inputAdd {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: transparent;
    border: 1px solid $main-border-color;
    border-radius: 10px;
    margin-left: 16px;

    &::after {
        content: ' ';
        position: absolute;
        display: block;
        background-color: $main-border-color;
        height: 1px;
        width: 20px;
        top: 50%;
        left: 50%;
        z-index: 3;
        transform: translate(-50%, -50%);
    }
    &::before {
        content: ' ';
        position: absolute;
        display: block;
        background-color: $main-border-color;
        height: 1px;
        width: 20px;
        top: 50%;
        z-index: 3;
        transform: rotate(90deg);
    }

    &:hover,
    &:active {
        background-color: $background-lite;
    }
}

@mixin disabledStructure {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    flex-wrap: wrap;

    & span {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0;
        color: $black-text;
        margin-right: 10px;

        &:after {
            content: ',';
        }

        &:last-child {
            margin-right: 0;

            &:after {
                content: none;
            }
        }
    }
}

@mixin notSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    user-drag: none;
    -webkit-user-drag: none;
}

@mixin redDateHint {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: 20px;
    width: 100%;
    color: $brand-red;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.1px;
}

@mixin taskRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-width: 300px;
    width: 100%;
    margin-bottom: 22px;
}

@mixin row {
    min-width: 100px;
    width: 100%;
    max-width: 250px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    font-weight: 400;
    letter-spacing: 0.1px;
    font-style: normal;
}

@mixin toTopBtn {
    cursor: pointer;
    display: flex;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    min-width: 38px;
    width: 38px;
    min-height: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: $brand-white;
    box-shadow: 0 0 8px 0 $btn-shadow;
    margin-bottom: 25px;

    .arrowUpIcon {
        width: 20px;
        height: 19px;
    }
}

@mixin sendButton {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    min-width: 22px;
    width: 22px;
    min-height: 22px;
    height: 22px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $menu-item-gray;

    & > svg {
        padding-left: 2px;
        min-width: 14px;
        min-height: 14px;
    }

    @media screen and (max-width: $breakpoint-landscape-tablet) {
        display: flex;
    }
}

@mixin dropShadow {
    box-shadow: 0 3.5px 25.5px 0 #00000012;
}
