@import '../../styles/_colors';
@import '../../styles/_breakpoints';

.authBlock {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: #f7f7f7;
}
.signInContainer {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    background-color: #f7f7f7;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;

    .authForm {
        display: flex;
        flex-direction: column;
        min-width: 300px;
        max-width: 700px;
        border-radius: 10px;
        background-color: $brand-white;
        padding: 30px;

        .formTitle {
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.1px;
            color: $text-gray;
            margin-bottom: 30px;
        }

        .mb20 {
            margin-bottom: 20px;
        }

        .btnWrapper {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
        }
    }

    .signInBtn {
        font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
        cursor: pointer;
        height: 40px;
        background-color: $brand-white;
        border-radius: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: $brand-green;
        padding: 12px 14px;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        box-shadow: 0 0 8px 0 $btn-shadow;
    }
}

@media screen and (max-width: $breakpoint-medium-mobile) {
    .signInContainer {
        .authForm {
            margin: 0 auto;
            min-width: 95%;
            max-width: 100%;
        }
    }
}
