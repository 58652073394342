@import '../../../styles/_colors';

.spinnerContainer {
    display: flex;
    flex: 1 1 auto;
    justify-items: center;
    justify-content: center;
    align-items: center;
    align-content: center;

    .spinner {
        width: 7rem;
        height: 7rem;
        position: relative;

        .doubleBounce1,
        .doubleBounce2 {
            width: 0;
            height: 100%;
            border-radius: 50%;
            background-color: $brand-green;
            opacity: 0.6;
            position: absolute;
            top: 0;
            left: 0;
            -webkit-animation: skBounce 2s infinite ease-in-out;
            animation: skBounce 2s infinite ease-in-out;
        }

        .doubleBounce1 {
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
        }

        .doubleBounce2 {
            -webkit-animation-delay: 2s;
            animation-delay: 2s;
        }

        @-webkit-keyframes skBounce {
            0%,
            100% {
                width: 100%;
                -webkit-transform: scale(0);
            }
            50% {
                width: 100%;
                -webkit-transform: scale(1);
            }
        }

        @keyframes skBounce {
            0%,
            100% {
                width: 100%;
                transform: scale(0);
                -webkit-transform: scale(0);
            }
            50% {
                width: 100%;
                transform: scale(1);
                -webkit-transform: scale(1);
            }
        }
    }
}
