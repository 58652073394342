@import '../../../styles/_colors';

.menuContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
    font-style: normal;
    max-height: 200px;
    padding: 6px 0;
}

.menuItem {
    font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
    color: $brand-grey-menu-item;
    font-weight: 500;
    font-size: 10px !important;
    line-height: 14px;
    letter-spacing: 0.1px;
    padding: 4px 10px;
    max-width: 180px;
    width: 100%;

    &:hover,
    &:active {
        background-color: transparent;
        color: $brand-green;
    }
}

.iconMoreMenu {
    &:hover,
    &:focus,
    &:active {
        background-color: transparent !important;
    }
}
