@import '../../styles/_colors';
@import '../../styles/_breakpoints';

.mainComponentContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    justify-content: center;
    background-color: $main-background-color;

    .mainInnerContainer {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        justify-content: center;
        background-color: transparent;
        overflow-x: auto;
    }
}

@media (max-width: $breakpoint-laptop) {
    .mainComponentContainer {
        .mainInnerContainer {
            width: 100%;
            justify-content: normal;
        }
    }
}
