@import '../../../styles/_colors';

.errorContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    margin-bottom: 30px;
    height: 100%;

    .errorInner {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .errorTitle {
            font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
            font-weight: 500;
            color: $text-gray;
            font-size: 21px;
            line-height: 24px;
            margin-bottom: 15px;
        }
    }
}
