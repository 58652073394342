@import '../../../styles/_colors';
@import '../../../styles/_breakpoints';

.appHeader {
    display: flex;
    width: 100%;
    min-height: 70px;
    height: 70px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $brand-white;
    box-shadow: inset 0 -1px 0 $main-border-color;

    .appHeaderInner {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 70px;
        height: 70px;
        min-width: 300px;
        width: 100%;
        max-width: 1440px;

        .headerFirstBlock {
            display: flex;
            min-width: 70px;
            max-width: 250px;
            width: 100%;
            flex-grow: 1;
            flex-direction: row;
            align-items: center;

            .headerMenuIcon {
                display: none;
                cursor: pointer;
                min-width: 22px;
                width: 22px;
                height: 14px;
                min-height: 14px;
                object-fit: contain;
                margin-left: 20px;
            }

            .headerSimpleLogo {
                margin-left: 51px;
                width: 146px;
                height: 35px;
                align-self: center;
            }

            .headerLogoBlock {
                margin-left: 51px;

                .headerLogo {
                    width: 146px;
                    height: 35px;
                    align-self: center;
                }
            }

            .ml20 {
                margin-left: 20px;
            }

            .ml28 {
                margin-left: 28px;
            }
        }

        .headerSecondBlock {
            display: flex;
            flex-direction: row;
            flex: 1 1 auto;
            margin: 0 15px;
            max-width: 1161px;
            justify-content: flex-end;
            align-items: center;

            .notifyIconsBlock {
            }

            .profileBlock {
                display: flex;
                flex-direction: row;
                align-items: center;

                .name {
                    font-size: 14px;
                    line-height: 16px;
                    color: $menu-item-gray;
                    margin-right: 15px;
                    min-width: 70px;
                    max-width: 220px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .photo {
                    min-width: 32px;
                    width: 32px;
                    height: 32px;
                    min-height: 32px;
                    border-radius: 50%;
                    padding: 0;
                    background: $main-gray-color;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-laptop) {
    .appHeader {
        .appHeaderInner {
            .headerFirstBlock {
                .headerMenuIcon {
                    display: flex;
                }

                .headerLogoBlock {
                    margin-left: 28px;
                }
            }
            .headerSecondBlock {
                .profileBlock {
                    .name {
                        display: none;
                        margin-right: 0;
                        min-width: 0;
                    }
                }
            }
            .headerSimpleLogo {
                margin-left: 28px;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .appHeader {
        .appHeaderInner {
            .headerFirstBlock {
                width: 0;
                min-width: 0;

                .headerMenuIcon {
                    position: absolute;
                    left: 29px;
                    margin: 0;
                    min-width: 22px;
                    width: 22px;
                    padding: 3px 2px;
                    height: 14px;
                    min-height: 14px;
                    z-index: 5;
                }

                .headerLogoBlock {
                    display: none;
                }
            }

            .headerSecondBlock {
                margin: 0;

                .profileBlock {
                    position: absolute;
                    right: 25px;
                    z-index: 5;
                }

                .notifyIconsBlock {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-medium-mobile) {
    .appHeader {
        .appHeaderInner {
            .headerFirstBlock {
                .headerMenuIcon {
                    display: flex;
                }

                .headerLogoBlock {
                    margin-left: 18px;
                    width: 35px;
                    max-width: 35px;
                    overflow: hidden;
                }
            }

            .headerSimpleLogo {
                margin-left: 18px;
                width: 35px;
                max-width: 35px;
                overflow: hidden;
            }
        }
    }
}
