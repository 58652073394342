@import '../../../../styles/_colors';

.item {
    margin-top: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;

    .title {
        color: $text-light-gray;
        font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
    }

    .subtitle {
        color: $text-gray-secondary;
        font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
        font-size: 12px;
        line-height: 14px;
        margin-top: 5px;
    }

    .icon {
        min-width: 20px;
        width: 20px;
        min-height: 20px;
        height: 20px;
        margin-right: 12px;

        & > svg {
            color: $text-gray-secondary;
            fill: $text-gray-secondary;

            & path {
                fill: $text-gray-secondary;
                color: $text-gray-secondary;
            }
        }
    }
}
