$brand-light-gray: #b2b2b2;
$brand-green: #00923a;
$brand-green-hover: #00742e;
$brand-red: #e52322;
$brand-red-highlighted: #eb5757;
$text-gray: #333333;
$text-gray-secondary: #92929d;
$menu-item-gray: #828282;
$background-lite: #fafafb;
$brand-white: #ffffff;
$brand-black: #000000;
$brand-starred: #ffad26;
$text-light-gray: #44444f;
$main-gray-color: #878786;
$main-border-color: #e2e2ea;
$main-hr-color: #f5f6fa;
$main-background-color: #f2f2f2;
$container-border-color: #f1f1f5;
$grey-background-color: #f5f5f5;
$grey-lines: #e1e1e1;
$dropdown-gray: #696973;
$icons-gray: #bdbdbd;
$brand-grey-menu-item: #4f4f4f;
$table-point-default: #c4c4c4;
$icon-notify: #fc5a5a;
$black-text: #171725;
$btn-shadow: rgba(60, 60, 72, 0.1);
$brand-green-border: #01b948;
$concept-outline: #00cb4f;
$concept-search-placeholder: #00d352;
$concept-gray: #706f6f;
$concept-black: #050505;
$concept-black-title: #111111;
$concept-list-border: #3b3b3b1a;
$concept-green: #84b51e;
$concept-date-text: #11111180;
$concept-gray-text: #706f6f;
$concept-btn-background: #efefef;
$concept-btn-color: #02923a;
$concept-helper-title: #5a5a5a;
$concept-helper-sub: #2d2d2d80;
$perekrestok-green: #1eaf37;
$perekrestok-lightgreen: #5faf2c;
$perekrestok-notify: #3ebd39;
$perekrestok-search-placeholder: #3232321a;
$perekrestok-dark-green: #005a28;
$perekrestok-bg: #e3e3e1;
$perekrestok-btn-color: #1b8f45;
$perekrestok-tab-color: #0b592e;
$concept-feed-header: #e9e9ef80;
$concept-black-text-80: #000000cc;
$concept-red: #e42422;
$perekrestok-btn-border: #0000001a;
$perekrestok-video-hover: #0000000d;
$perekrestok-video-input: #00000008;
$perekrestok-input-placeholder: #00000033;
