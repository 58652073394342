@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.appHeader {
    display: flex;
    width: 100%;
    min-height: 70px;
    height: 70px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $brand-white;
    box-shadow: inset 0 -1px 0 $main-border-color;
    border-bottom: 1px solid #2929291a;
    font-family: '5ka Sans Design', 'Roboto', Apple-System, Arial, Helvetica,
        sans-serif;
}

.appHeaderInner {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 70px;
    height: 70px;
    min-width: 300px;
    width: 100%;
    max-width: 1200px;
}

.headerFirstBlock {
    display: flex;
    min-width: 70px;
    max-width: 168px;
    width: 100%;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
}

.headerMenuIconBlock {
    display: none;
    cursor: pointer;
}

.headerMenuIcon {
    display: none;
    cursor: pointer;
    min-width: 22px;
    width: 22px;
    height: 14px;
    min-height: 14px;
    object-fit: contain;
    margin-left: 20px;
}

.moreIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 9px 1px;
}

.menuWrapper {
    padding: 0;
    border-radius: 50px;
}

.menuItem {
    min-width: 223px;
    max-width: 223px;
    padding: 1px 5px !important;
}

.menuLogo {
    max-width: 223px;
}

.menuLogoLast {
    max-width: 223px;
    margin-top: 0;
}

.headerLogoBlock {
    height: 21.77px;
}

.headerLogo {
    width: 167.67px;
    height: 21.77px;
    align-self: center;
}

.headerSecondBlock {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    margin: 0 0 0 15px;
    max-width: 1161px;
    justify-content: flex-end;
    align-items: center;
}

.profileBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.photo {
    min-width: 32px;
    width: 32px;
    height: 32px;
    min-height: 32px;
    border-radius: 50%;
    padding: 0;
    background: $main-gray-color;
}

.headerNavbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 50px;
    margin-right: 6px;
}

.headerLink {
    cursor: pointer;
    color: $brand-black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1.1px;
    font-size: 14px;
    line-height: 16px;
    position: relative;

    &:active,
    &:hover {
        color: $brand-black;
    }
}

.headerLinkActive {
    position: absolute;
    bottom: 0;
    height: 4px;
    width: 120px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    background-color: $perekrestok-lightgreen;
}

.navBarSmall {
    display: flex;
    flex-direction: column;
    min-width: 320px;
    width: 320px;
    max-width: 320px;
    min-height: 100vh;
    height: 100%;
    background-color: $perekrestok-btn-color;
    padding: 20px 30px 20px 37.5px;
    font-family: '5ka Sans Design', 'Roboto', Apple-System, Arial, Helvetica,
        sans-serif;
}

.navBarHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
}

.navBarIcon {
    cursor: pointer;
    max-width: 24px;
    height: 24px;
    padding: 7px 4px;

    & path {
        fill: $brand-white;
    }
}

.navBarLogo {
    cursor: pointer;
    width: 130px;
}

.navBarLink {
    display: flex;
    width: 100%;
    color: $brand-white;
    padding: 15px 0;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1px;
    font-weight: 600;
    text-decoration: none;
}

@media screen and (max-width: $concept-breakpoint-desktop) {
    .moreIconWrapper {
        display: none;
    }

    .appHeaderInner {
        max-width: 100%;
        padding: 0 20px;
    }

    .headerFirstBlock {
        max-width: 16px;
        width: 16px;
        min-width: 16px;
        margin: 0;
        padding: 0;
    }

    .headerLink,
    .headerLinkActive,
    .headerLogoBlock,
    .notifyIconsBlock {
        display: none;
        margin: 0;
    }

    .headerMenuIconBlock {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        min-width: 40px;
        margin: 0;
    }

    .headerMenuIcon {
        display: flex;
        min-width: 16px;
        width: 16px;
        max-width: 16px;
        min-height: 10px;
        height: 10px;
        max-height: 10px;
        margin: 0;

        & > path {
            fill: $text-gray-secondary;
        }
    }

    .headerFirstBlock {
        position: absolute;
        z-index: 2;
        left: 20px;
    }

    .headerSecondBlock {
        position: absolute;
        right: 30px;
        margin: 0;
        z-index: 2;
    }

    .photo {
        min-width: 24px;
        max-width: 24px;
        width: 24px;
        min-height: 24px;
        height: 24px;
        max-height: 24px;
        border: 1px solid $brand-white;
        border-radius: 50%;
    }

    .headerSimpleLogo {
        margin-left: 28px;
    }
}
