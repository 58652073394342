@import '../../../styles/_colors';

.cookiesModalContainer {
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    z-index: 999999;
    min-width: 100%;
    width: 100%;
    min-height: 50px;
    background-color: $brand-white;
    bottom: 0;
    padding: 25px;

    & > p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $text-gray;
        margin-bottom: 0;

        & > a {
            font-size: 14px;
        }
    }

    .closeIcon {
        background-color: transparent;
        outline: none;
        border: none;
        margin-left: auto;
        margin-bottom: auto;

        cursor: pointer;
        min-width: 20px;
        width: 20px;
        min-height: 20px;
        height: 20px;

        &:hover,
        &:active {
            & path {
                fill: $brand-green;
            }
        }
    }
}
