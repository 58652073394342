@import '../../../../styles/_colors';

.resultGroup {
    margin-bottom: 20px;

    .header {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        gap: 15px;

        .title {
            font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
            color: $main-border-color;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1px;
            white-space: nowrap;
        }
        .line {
            border-bottom: $main-border-color solid 1px;
            height: 1px;
            width: 100%;
        }
        .triangle {
            cursor: pointer;
        }
    }

    .loadMore {
        cursor: pointer;
        display: block;
        margin-top: 20px;
        font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.1px;
        color: $text-light-gray;
    }
}
