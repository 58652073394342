@import '../../../styles/_colors';
@import '../../../styles/_breakpoints';

.modalContainer {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
    height: 100%;
    min-width: 300px;
    min-height: 100px;
    max-width: 940px;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 10px;
    background-color: $brand-white;
    border: 0.3px solid $brand-light-gray;
    outline: none;
    flex: 1;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);

    .innerWrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        justify-content: space-between;
    }
}

@media screen and (max-width: $breakpoint-laptop) {
    .modalContainer {
        max-width: 772px;
    }
}

@media screen and (max-width: $breakpoint-tablet) {
    .modalContainer {
        max-width: 674px;
    }
}
