@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 100;
    src: url(./fonts/Roboto-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: url(./fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: url(./fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: url(./fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: '5ka Sans Design';
    src: url(./fonts/5kaSansDesign-Regular.otf) format('opentype');
}

@font-face {
    font-family: '5ka Sans Design';
    font-weight: 100;
    src: url(./fonts/5kaSansDesign-Thin.otf) format('opentype');
}

@font-face {
    font-family: '5ka Sans Design';
    font-weight: 300;
    src: url(./fonts/5kaSansDesign-Light.otf) format('opentype');
}

@font-face {
    font-family: '5ka Sans Design';
    font-weight: 400;
    src: url(./fonts/5kaSansDesign-Regular.otf) format('opentype');
}

@font-face {
    font-family: '5ka Sans Design';
    font-weight: 600;
    src: url(./fonts/5kaSansDesign-SemiBold.otf) format('opentype');
}

@font-face {
    font-family: '5ka Sans Design';
    font-weight: 900;
    src: url(./fonts/5kaSansDesign-Black.otf) format('opentype');
}

ul.checkboxList,
ul.dashesList {
    list-style: none;
    padding-left: 15px;
}
ul.checkboxList li::before {
    content: '\2611'; /* 🗹 */
    margin: 0 0.3em;
}
ul.dashesList li::before {
    content: '\002D'; /* - */
    margin: 0 0.3em;
}
