@import '../../../styles/colors';
@import '../../../styles/breakpoints';

.wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 282px;
    width: 100%;
    max-width: 282px;
    height: 36px;
    color: $brand-white;
}

.searchInput {
    width: 100%;
    font-family: '5ka Sans Design', Apple-System, Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 1.1px;
    background: transparent;
    border: none;
    border-radius: 8px;
    height: 100%;
    padding: 0 30px 0 30px;
    color: $brand-white;

    &::placeholder {
        color: $brand-white !important;
        font-family: '5ka Sans Design', Apple-System, Arial, Helvetica,
            sans-serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 1.1px;
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:focus-visible,
    &:target,
    &:active {
        outline: 1px solid $concept-outline !important;
    }

    &:disabled {
        opacity: 0.6;
    }
}

.searchInputWithValue {
    outline: 1px solid $concept-outline;
}

.closeIcon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    padding: 4px;
    width: 22px;
    height: 22px;
    z-index: 3;
}

.searchInputIcon {
    cursor: pointer;
    position: absolute;
    left: 12px;
    min-width: 12px;
    width: 12px;
    min-height: 12px;
    height: 12px;
    z-index: 3;

    & path {
        fill: $concept-outline;
    }
}

.wrapperPerek {
    min-width: 236px;
    max-width: 236px;

    .searchInput {
        color: $brand-black;

        &::placeholder {
            color: $brand-black !important;
        }

        &:hover,
        &:focus,
        &:focus-within,
        &:focus-visible,
        &:target,
        &:active {
            outline: 1px solid $perekrestok-search-placeholder !important;
        }
    }

    .searchInputWithValue {
        outline: 1px solid $perekrestok-search-placeholder;
    }

    .searchInputIcon {
        & path {
            fill: $icons-gray;
        }
    }
}

.searchErrorHelper {
    position: absolute;
    left: 40px;
    bottom: -12px;
    color: $brand-red;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 0.1px;
}

@media screen and (max-width: $concept-breakpoint-desktop) {
    .wrapper {
        min-width: 282px;
        width: 100%;
        max-width: 100%;
        height: 42px;
    }

    .wrapperPerek {
        min-width: 236px;
    }

    .searchInput {
        padding: 0 64px 0 44px;
        outline: 1px solid $concept-outline !important;

        &::placeholder {
            color: $concept-search-placeholder !important;
            font-family: '5ka Sans Design', Apple-System, Arial, Helvetica,
                sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.1px;
        }
    }

    .wrapperPerek .searchInput {
        outline: 1px solid $perekrestok-search-placeholder !important;

        &::placeholder {
            color: $brand-black !important;
        }
    }

    .searchInputIcon {
        display: none;
    }

    .closeIcon {
        right: 40px;
    }

    .searchErrorHelper {
        bottom: -14px;
    }
}
