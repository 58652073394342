@import '../../../styles/_colors';
@import '../../../styles/_variables';

.defaultBtn {
    @include notSelect;
    font-family: 'Roboto', Apple-System, Arial, Helvetica, sans-serif;
    cursor: pointer;
    height: 32px;
    background-color: $brand-white;
    border-radius: 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: $brand-light-gray;
    padding: 9px 12px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0 0 8px 0 $btn-shadow;
}

.newFont {
    font-family: '5ka Sans Design', Apple-System, Arial, Helvetica, sans-serif;
}

.approveBtn {
    color: $brand-green;

    &:hover,
    &:active {
        color: $brand-green-hover;
    }
}

.cancelBtn {
    color: $brand-red;

    &:hover,
    &:active {
        color: $brand-white;
        background-color: $brand-red;
    }
}

.disabledBtn {
    color: $main-gray-color;
}

.bigButton {
    padding: 12px 16px;
    height: 38px;
}

.greenHover:hover,
.greenHover:active {
    color: $brand-white;
    background-color: $brand-green;
}

.concept,
.perek {
    font-family: '5ka Sans Design', Apple-System, Arial, Helvetica, sans-serif;
}

.concept {
    color: $concept-btn-color;

    &:hover,
    &:active {
        color: $brand-white;
        background-color: $concept-btn-color;
    }
}

.perek {
    color: $perekrestok-btn-color;

    &:hover,
    &:active {
        color: $brand-white;
        background-color: $perekrestok-btn-color;
    }
}

.perekPrimary {
    font-size: 12px;
    font-weight: 900;
    line-height: 14.44px;
    text-align: center;
    height: 38px;
    background-color: $perekrestok-lightgreen;
    color: $brand-white;
    padding: 13px 20px;

    &:hover,
    &:active {
        opacity: 0.8;
    }
}
