@import '../../../../styles/_colors';

.searchResult {
    display: flex;
    flex-direction: column;

    .itemSubtext span {
        margin-right: 15px;
    }

    .emptyResult {
        display: flex;
        width: auto;
        justify-content: center;
    }
}
