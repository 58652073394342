@import '../../../styles/_colors';
@import '../../../styles/_variables';
@import '../../../styles/_breakpoints';

.notifyBlock {
    @include notSelect;
    display: flex;
    flex-direction: row;
    margin-right: 23px;
    justify-content: center;
    align-items: center;
    position: relative;

    .messageIconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 20px;

        .messIcon {
            cursor: pointer;
            min-width: 22px;
            width: 22px;
            height: 22px;

            & path {
                fill: $icons-gray;
                color: $icons-gray;
            }

            &:hover,
            &:active {
                & path {
                    fill: $menu-item-gray;
                    color: $menu-item-gray;
                }
            }
        }

        .messIconActive {
            & path {
                fill: $menu-item-gray;
                color: $menu-item-gray;
            }
        }

        .messNotify {
            position: absolute;
            top: -2px;
            right: 0;
            min-width: 8px;
            width: 8px;
            height: 8px;
            background: $icon-notify;
            border-radius: 50%;
        }
    }

    .taskIconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .taskIcon {
            cursor: pointer;
            min-width: 19px;
            width: 19px;
            height: 22px;

            & path {
                fill: $icons-gray;
                color: $icons-gray;
            }

            &:hover,
            &:active {
                & path {
                    fill: $menu-item-gray;
                    color: $menu-item-gray;
                }
            }
        }

        .taskIconActive {
            & path {
                fill: $menu-item-gray;
                color: $menu-item-gray;
            }
        }

        .taskNotify {
            position: absolute;
            top: -2px;
            right: 0;
            min-width: 8px;
            width: 8px;
            height: 8px;
            background: $icon-notify;
            border-radius: 50%;
        }
    }

    .popupNotify {
        position: absolute;
        right: -93px;
        top: 45px;
        z-index: 5;
        padding: 25px 17px;
        background-color: $brand-white;
        border: 1px solid $main-border-color;
        max-width: 385px;
        width: 385px;
        min-width: 385px;
        transition: width 2s, height 2s, background-color 2s, transform 2s;
        max-height: 77vh;
        box-sizing: border-box;
        overflow-y: auto;

        .notifyBlockHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 0;

            .msgIcon {
                min-width: 22px;
                width: 22px;
                height: 22px;
                margin-right: 10px;

                & path {
                    fill: $icons-gray;
                    color: $icons-gray;
                }
            }

            .bellIcon {
                min-width: 19px;
                width: 19px;
                height: 22px;
                margin-right: 10px;

                & path {
                    fill: $icons-gray;
                    color: $icons-gray;
                }
            }

            & span {
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.1px;
                color: $text-gray;
            }
        }

        .emptyText {
            display: flex;
            align-content: center;
            justify-content: center;
            margin-top: 20px;
            width: 100%;
            text-align: center;
        }

        .notifyElement {
            display: flex;
            flex-direction: column;

            .groupTitle {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 30px;

                & span {
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 13px;
                    color: $text-light-gray;
                    margin-right: 10px;
                }

                .groupHr {
                    @include hrStyle;
                }
            }

            .elementInfo {
                display: flex;
                flex-direction: column;
                margin-top: 10px;

                .elementTitle,
                .elementComment {
                    cursor: pointer;
                    max-width: 316px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 24px;
                    letter-spacing: 0.1px;
                    color: $text-gray;
                    margin-bottom: 3px;
                }

                .elementComment {
                    color: $text-light-gray;
                    font-weight: 400;
                }

                .elementDetails {
                    display: flex;
                    flex-direction: row;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 12px;
                    color: $icons-gray;

                    .elementCategory {
                        margin-right: 11px;
                    }

                    .elementDate {
                        margin-right: 15px;
                    }

                    .elementAction {
                        display: none;
                        cursor: pointer;
                        font-size: 10px;
                        line-height: 12px;
                        color: $brand-grey-menu-item;

                        &:hover,
                        &:active {
                            text-decoration: underline;
                        }
                    }
                }

                &:hover,
                &:active {
                    .elementAction {
                        display: flex;
                    }
                }
            }
        }

        .hideAllBlock {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            .hideAllBtn {
                cursor: pointer;
                font-family: 'Roboto', Apple-System, Arial, Helvetica,
                    sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                color: $main-gray-color;
                margin-top: 30px;

                &:hover,
                &:active {
                    text-decoration: underline;
                }
            }
        }
    }
}

.whiteIcons {
    .messIcon path,
    .taskIcon path {
        fill: $brand-white !important;
    }

    .taskNotify,
    .messNotify {
        background: $brand-red !important;
    }
}

.darkGreyIcons {
    .messIcon path,
    .taskIcon path {
        fill: $text-gray-secondary !important;
    }

    .taskNotify,
    .messNotify {
        background: $perekrestok-notify !important;
    }
}

@media (max-width: $breakpoint-medium-mobile) {
    .notifyBlock {
        .popupNotify {
            max-width: 300px;
            width: 300px;
            min-width: 300px;

            .notifyElement .elementInfo .elementTitle,
            .notifyElement .elementInfo .elementComment {
                max-width: 260px;
            }
        }
    }
}
